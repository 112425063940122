import {ref, reactive} from "vue";
import TwilioClientObserver from "./TwilioClientObserver";
import axios from "axios";
import {useConversation} from "../Stores/Conversation";
import {useWorkspace} from "../Stores/Workspaces";
import {useAppState} from "../Stores/AppState";
import trackUserInactive from "./track-user-inactivity";
import {useUserStore} from "../Stores/User";
import {useInvitation} from "../Stores/Invitation";
import {de} from "vuetify/locale";
import {useTimeTrackerStore} from "../Stores/TimeTracker";
import {useRequest} from "../Components/Chat/Store/Request";
import {useTeam} from "../Components/Custom/Team/Store/Team";
import {useAppState as useAppSateScreenShot} from "../Components/ScreenShots/Store/AppState";
import {checkImageExists, grabImageUrls, getIndexFromImageSrc} from "./Media";
import HttpStatusCodes from "../Constants/http.js";
import Utilities from "../Helpers/utilities.js";

function createConversationForOpenedListItem() {
}

// import conversationApi from '../../../Admin/js/composition-api/conversations'
// const { loadTokenForConversation,
function updateConversationLastMessageSentAt(conversation_id, data) {
    axios.post(`/conversations/${conversation_id}/message-sent-at`, data);
}

function getCurrentConversationId() {
    return useConversation().active?.id;
}

function createTwilioConversationForConversation() {
}

function showConnectingIndicator() {
}

function hideConnectingIndicator() {
}

function fetchConversationById() {
}

// import {useConversation} from '../Stores/Conversation'
// import {de} from "vuetify/locale";

function updateConversationListOrderingWhenConversationReceivedAMessage(
    conversation_id,
) {
    const conversationStore = useConversation();
    conversationStore.moveConversationToTop(conversation_id);
}

function selectConversationAsCurrentById() {
}

function updateCurrentConversationDraft() {
}

function getDraftForConversation() {
}

// } = conversationApi();

function updateUnreadMessagesCountBy() {
} // from '../composition-api/helpers';
// import LoggedInUserApi from '../composition-api/logged-in-user';
function loggedInUserChatIdentity() {
    return window.profile?.twilio_identity;
}

function loggedInUserName() {
    return window.profile.name;
}

function getItemById() {
}

function setConverstionForItem() {
}

function prepareBreadCumbForBoardAndItem() {
}

const {subscribeWithIdentity} = TwilioClientObserver();

const intlDateTimeFormatter = ref(
    new Intl.DateTimeFormat("en", {
        dateStyle: "medium" /* timeStyle: 'short' */,
    }),
);
const intlDateTimeFormatterOnlyTime = ref(
    new Intl.DateTimeFormat("en", {timeStyle: "short"}),
);
const {is_inactive} = trackUserInactive();
const unread_message_timeout = ref(null);

const STATUS = {
    TYPING: "typing",
    ACTIVE: "active",
    INACTIVE: "inactive",
    ABSENT: "absent",
    CONNECTED: "connected",
    OFFLINE: "offline",
    FAILED: "failed",
};

const MESSAGE = {
    STATUS: "status",
    TEXT: "text",
    MEDIA: "media",
    META: "meta",
    STATE: "state",
    IMAGE: "image",
    VIDEO: "video",
    PAYMENT: "payment",
    INVITE: "invite",
    UNINVITE: "uninvite",
    ASSIGN: "assign",
    UNASSIGN: "unassign",
    NAVIGATION: "navigation",
    ATTENTION: "attention",
    ATTENTION_CLOSED: "attention_closed",
    REQUEST_STATUS: "request_status",
    REQUEST_STATUS_CLOSED: "request_status_closed",
    SUPPORT: "support",
    SUPPORT_CLOSED: "support_closed",
    TIMER: "timer",
    CONVERSATION: "conversation",
    ACTION: "action",
};

const TYPE = {
    ITEM: "item",
    ITEM_FEEDBACK: "feedback",
    BOARD: "board",
    WORKSPACE: "workspace",
    GROUP: "group",
    SPACE: "workspace",
    GROUP_ONE_OFF: "group-one-off",
    DM: "dm",
};

class Conversation {
    conversationsClient = null;

    MESSAGES_CHUNK_SIZE = 50;

    state = reactive({
        messages: [],
        bufferedMessages: [],
        reserved_messages: [],
        status: "No Conversation",
        hideLoadingIndicator: false,
        typingUser: "",
        messageTyped: "",
        isConversationPreparationInProgress: 0,
        name: "",
        id: null,
        sid: null,
        type: null,
        type_id: null,
        participants: [],
        last_message_send_at: null,
        space_id: null,
        space_name: null,
        board_id: null,
        board_name: null,
        assignedUser: null,
        is_initial_messages_fetched: false,
        is_archived: false,
        is_paginated: false,
        is_clear_messages: false,
        twilioConversation: null,
        typingUsers: [],
        is_invitation_in_progress: false,
        isLoadingMoreMessages: false,
        isCreatingConversation: false,
        lastMessageIndexFromNewlyLoadedMessages: null,
        total_messages: 0,
        urgent_request: [],
        unread_message_count: 0,
        originalData: [],
        message_fetching_attempt: 0,
        avatar_url: null
    });

    twilioConversation = reactive({
        value: null,
    });

    cachedMessages = [];

    // originalData = null;

    //userTypedLastAt = null;

    //typingTimeOutHandler = null;

    //typingThrottler = null;

    //usersTypingSet = new Set();

    myCurrentStatus = "";

    activeUsers = []; //Seems not required anymore

    inActiveUsers = []; //Seems not required anymore

    absentUsers = []; //Seems not required anymore

    //offlineUsers = [];

    //isMuted = false;

    //unreadMessagesCount = 0;

    messagesNotProcessed = [];

    isLastReadUsersOnMessagesSet = false;

    //totalParticipants = 0;

    //twilioParticipants = null;

    didISetCurrentConversationStateToLoading = false;

    auth_user_chat_identity = null;

    authUser = null;

  //  avatar_url = null;

    //chat_access_token = null;

    scrollBarHandler = () => {
    };

    toggleUserOfInactivity = () => {
    };

    updateUserStatusIndicators = () => {
    };

    isRegisterdForMessageAddedEvent = false;

    lastCurrentUrlNotificationSentAt = null;

    isFakeConversation = false;

    recent_active_panel = null; //Seems not required anymore

    isRequestToCreateTwilioConversationAlreadyMade = false;

    isSendingBufferedMessagesInProgress = false;

    // isToSendGroupMessage = false;

    // isToSendBoardMessage = false;

    space = null;

    board = null;

    loadingMoreMessagesTimeOut = null;

    index_key = 0;

    key = null;

    constructor(conv, isInitialize = true) {
        this.setConversationVariables(conv);
        if (conv.is_quick_load || isInitialize) {
            this.initialize();
        }
        this.key = this.state.id || Date.now();
    }

    async initialize(dontUpdateStatus = false) {
        if (!this.getConversationSid()) return;
        this.setUpAndPrepareRequiredFields();
        !dontUpdateStatus && this.setStatusToPreparing();

        this.prepare(); // prepares Twilio Client
        //this.setAssignedUser()
    }

    async setAssignedUser(user_id = null) {
        if (!user_id) {
            useInvitation()
                .getInvitedMembers(this)
                .then((res) => {
                    this.state.assignedUser =
                        res?.data?.assigned_profile_id ?? null;
                });
        } else this.state.assignedUser = user_id;
    }

    // setAssignedUser(user_id = null) {
    //     if (!user_id) {
    //         useInvitation().getInvitedMembers(this)
    //             .then (res => {
    //                 user_id = res?.data?.assigned_profile_id ?? null
    //                 this.state.assignedUser = user_id
    //             })
    //     }
    //     this.state.assignedUser = user_id
    // }

    setUpAndPrepareRequiredFields() {
        this.removeAsFakeConversation();
        this.prepareUserChatIdentity();
    }

    prepareUserChatIdentity() {
        //const participants = this.getParitcipants();
        const user_profile = window.profile; //participants?.find((paricipant) => paricipant.twilio_identity == loggedInUserChatIdentity());
        this.auth_user_chat_identity = user_profile?.twilio_identity;
        this.authUser = user_profile;
    }

    resetAfterContactsInvited(conversation) {
        this.setConversationVariables(conversation);
        this.forceUiUpdate();
        return;
        /*
            User can be invited directly - need to create a conversation and add 2 users
            User cna be invited on Conversation - just add participant
            */
        /* if no conversation created before, then do it now */
        if (this.hasTwilioConversationSet()) return;
        // create conversation now
        //console.log('Creating conversation....');
        this.initialize();
    }

    sendUnInvitationMessage(new_profiles) {
        let names = "";

        const current_conversation = useConversation().active;
        let authUser = current_conversation?.authUser;

        new_profiles.forEach((v) => {
            names += "&#x2022; " + v.name + "<br />";
        });
        const message = authUser.first_name + " has removed: <br />" + names;

        this.sendMessage(message);
    }

    getNewInviteProfile(old_participants, data) {
        if (!useUserStore().newInvitedMember) return [];
        else {
            useUserStore().setNewInvitedMember(false);

            const current_conversation = useConversation().active;
            const current_conversation_participants =
                current_conversation.participants;

            current_conversation?.prepareUserChatIdentity();
            let authUser = current_conversation?.authUser;
            let profiles = [];

            current_conversation_participants.forEach((v) => {
                if (authUser.twilio_identity != v.twilio_identity) {
                    if (old_participants.length == 0) {
                        profiles.push(v);
                        return;
                    }

                    if (
                        old_participants.find(
                            (ov) => v.twilio_identity != ov.twilio_identity,
                        )
                    ) {
                        profiles.push(v);
                    }
                }
            });

            return profiles;
        }
    }

    setConversationVariables(conv) {
        if (!conv)
            return console.trace("conversation details are not valid", conv);

        this.originalData = conv;
        this.state.name = conv.type == "dm" ? "" : conv.name;
        this.state.id = conv.id;
        this.state.sid = conv.sid;
        this.state.type = conv.type;
        this.state.type_id = conv.type_id;
        this.state.space_id = conv.space_id;
        this.state.space_name = conv.space_name;
        this.state.board_id = conv.board_id;
        this.state.board_name = conv.board_name;
        this.state.participants = conv.participants ?? [];
        //this.totalParticipants = this.state.participants.length;
        this.state.last_message_send_at = conv.last_message_send_at;
        this.state.user_profile_id_assignee =
            conv?.item?.version?.user_profile_id_assignee;
        // this.auth_user_chat_identity = window.profile?.id;
        this.auth_user_chat_identity = window.profile?.twilio_identity;
        this.state.is_archived = false;
        this.state.is_paginated = conv?.is_paginated === true;
        // this.attachItemsSpaceAndBoard()

        this.state.avatar_url = conv.avatar_url;
        this.is_quick_load = conv.is_quick_load;

        this.prepareName();
        if (this.itemId) setConverstionForItem(this.itemId, this.id);
    }

    attachItemsSpaceAndBoard() {
        if (this.isItem()) {
            const workspace_store = useWorkspace();
            const mapped_space_and_board = workspace_store.getSpaceBoardForItem(
                this.type_id,
            );
            this.space = mapped_space_and_board.space;
            this.board = mapped_space_and_board.board;
            this.item = mapped_space_and_board.item;
        }
    }

    setCachedMessages(messages) {
        this.cachedMessages = messages?.length ? messages : [];
        if (!this.cachedMessages.length) return;

        this.dontShowLoadingIndicators();
        this.moveScrollBar();
    }

    clearCachedMessages() {
        this.cachedMessages = [];
    }

    setAsFakeConversation() {
        this.isFakeConversation = true;
    }

    setAsArchivedConversation() {
        this.state.is_archived = true;

        // clear counter on archive conversation.
        useConversation().addUnreadConversation(0, {
            id: this.id,
            itemId: this.itemId,
            name: this.name,
            lastMessageDateCreated:
            this.getTwilioConversation()?.lastMessage?.dateCreated,
        });
        this.unreadMessagesCount = 0;
    }

    setIsRequireClearMessages() {
        this.state.is_clear_messages = true;
    }

    removeAsFakeConversation() {
        this.isFakeConversation = false;
    }

    removeAsArchivedConversation() {
        this.state.is_archived = false;
    }

    isFake() {
        return this.isFakeConversation;
    }

    isArchived() {
        return this.state.is_archived;
    }

    isPaginated() {
        return this.state.is_paginated === true;
    }

    hasInitialMessages() {
        return this.state.is_clear_messages;
    }

    get id() {
        return this.state.id;
    }

    get sid() {
        return this.state.sid;
    }

    get name() {
        return this.state.name;
    }

    get type() {
        return this.state.type;
    }

    get type_id() {
        return this.state.type_id;
    }

    get itemId() {
        return this.state.type_id;
    }

    get resourceId() {
        return this.state.type_id;
    }

    get space_id() {
        if (this.state.space_id) {
            return this.state.space_id;
        }

        if (this.is_item) {
            const {space} = useWorkspace().getSpaceBoardForItem(this.itemId);
            return space?.id;
        }

        return null;
    }

    get space_name() {
        return this.state.space_name;
    }

    get board_id() {
        if (this.state.board_id) {
            return this.state.board_id;
        }

        if (this.is_item) {
            const {board} = useWorkspace().getSpaceBoardForItem(this.itemId);
            return board.id;
        }

        return null;
    }

    get avatar_url() {
        return this.state.avatar_url;
    }

    get board_name() {
        return this.state.board_name;
    }

    get is_space() {
        return this.state.type == TYPE.WORKSPACE;
    }

    get is_board() {
        return this.state.type == TYPE.BOARD;
    }

    get is_item() {
        return (
            this.state.type === TYPE.ITEM ||
            this.state.type === TYPE.ITEM_FEEDBACK
        );
    }

    get is_group() {
        return this.state.type == TYPE.GROUP;
    }

    get is_group_one_off() {
        return this.state.type == TYPE.GROUP_ONE_OFF;
    }

    get is_dm() {
        return this.state.type == TYPE.DM;
    }

    get lastMessageSentAt() {
        return this.state.last_message_sent_at;
    }

    get is_offline() {
        return (
            this.state.status == STATUS.OFFLINE ||
            this.state.status == STATUS.FAILED
        );
    }

    get unreadMessagesCount() {
        return this.state.unread_message_count;
    }

    get participants() {
        return this.state.participants;
    }

    get originalData() {
        return this.state.originalData;
    }

    /* get twilioConversation(){
        return this.state.twilioConversation
    } */

    set name(name) {
        this.state.name = name;
        this.forceUiUpdate();
    }

    set board_name(board_name) {
        return (this.state.board_name = board_name);
    }

    set space_name(space_name) {
        return (this.state.space_name = space_name);
    }

    set space_id(space_id) {
        return (this.state.space_id = space_id);
    }

    set avatar_url(avatar_url) {
        return (this.state.avatar_url = avatar_url);
    }

    set unreadMessagesCount(count) {
        return (this.state.unread_message_count = count);
    }

    set participants(participants) {
        this.state.participants = participants;
    }

    set originalData(original_data) {
        this.state.originalData = original_data;
    }

    updateKey() {
        this.key = Date.now() + (this.state.id ?? 10);
    }

    updateSpaceAndBoard(space, board) {
        if (board?.id) {
            this.state.board_id = board.id;
        }

        if (board?.name) {
            this.state.board_name = board.name;
        }

        if (space?.id) {
            this.state.space_id = space.id;
        }

        if (space?.name) {
            this.state.space_name = space.name;
        }

        if (space) {
            this.state.avatar_url = space?.avatar_url;
        }
    }

    updateName(name) {
        this.name = name;
    }

    updateNameAndId(name, id) {
        this.setMessageTyped("");
        this.state.id = id;
        this.name = name;
    }

    originalDetails() {
        const messages = [];
        // latest 100 messages only
        this.getMessages()
            ?.slice(-100)
            .forEach((message) => {
                messages.push({...message});
            });
        return {
            ...this.originalData,
            name: this.getName(),
            messages,
        };
    }

    boardNameIfContextual() {
        if (!this.isContextual()) return "";
        const item = getItemById(this.itemId);

        return item?.board_name;
    }

    getMesssageTyped() {
        return getDraftForConversation(this.sid);
        // return this.state.messageTyped ? this.state.messageTyped: '';
    }

    setMessageTyped(message) {
        // this.state.messageTyped = message
        updateCurrentConversationDraft(message);
    }

    getUserId() {
        return this.authUser.user_id;
    }

    authorName() {
        return this.authUser.user_name;
    }

    getUserIdentity() {
        return this.auth_user_chat_identity;
    }

    getParticipants() {
        return this.state.participants;
    }

    getActiveParticipats() {
        return this.getParticipants().filter(
            (profile) => profile.is_removed == false,
        );
    }

    getActiveParticipantsExcept(id) {
        return this.getParticipants().filter(
            (profile) => profile.is_removed == false && profile.id != id,
        );
    }

    getOtherActviceParticipats() {
        return this.getParticipants().filter(
            (profile) =>
                profile.is_removed == false &&
                useUserStore().isNotCurrentUser(profile.twilio_identity),
        );
    }

    getActiveParticipantIds() {
        return this.getActiveParticipats().map((profile) => profile.id);
    }

    getParticipantIds() {
        const ids = [];
        this.getParticipants()?.forEach((participant) =>
            ids.push(participant.id),
        );
        return ids;
    }

    getOtherParticipants() {
        let uniqe_participants = new Set();

        return this.state.participants?.filter((participant) => {
            if (
                uniqe_participants.has(participant.id) ||
                participant.twilio_identity == loggedInUserChatIdentity()
            )
                return false;

            uniqe_participants.add(participant.id);
            return true;
        });
    }

    getItemCategory() {
        if (!this.is_item) {
            return null;
        }
        return useWorkspace().getItemGroupByItemId(this.originalData?.item?.id);
    }

    getOtherActiveParticipants() {
        return this.getOtherParticipants().filter(
            (profile) => profile.is_removed == false,
        );
    }

    getOtherActiveParticipantIds() {
        return this.getOtherParticipants().map((profile) => profile.id);
    }

    filterNewlyInvitedProfilesFromList(updated_participant_profile_ids) {
        const current_participants = this.getActiveParticipats().map(
            (profile) => profile.id,
        );
        const new_profile_ids = updated_participant_profile_ids.filter(
            (x) => !current_participants.includes(x),
        );
        return new_profile_ids;
    }

    filterRemovedProfilesFromList(updated_participant_profile_ids) {
        const current_participants = this.getActiveParticipats().map(
            (profile) => profile.id,
        );
        const deleted_profile_ids = current_participants.filter(
            (x) => !updated_participant_profile_ids.includes(x),
        );
        return deleted_profile_ids;
    }

    getConversationId() {
        return this.state.id;
    }

    getConversationSid() {
        return this.sid;
    }

    getType() {
        return this.state.type;
    }

    isContextual() {
        return this.type == TYPE.ITEM;
    }

    isItem() {
        return this.type == TYPE.ITEM || this.type == TYPE.ITEM_FEEDBACK;
    }

    isBoard() {
        return this.type == TYPE.BOARD;
    }

    isSpace() {
        return this.type == TYPE.SPACE;
    }


    isGroup() {
        return this.type == TYPE.GROUP;
    }

    isDm() {
        return this.type == TYPE.DM;
    }

    isInvitationAllowed() {
        return !this.isDm();
    }

    getName() {
        return this.state.name;
    }

    prepareName() {
        if (this.state.name) return;
        /* even for Fake contexual conversations, default name is set i.e list item naem  */

        if (this.isGroup() || this.isDm() || this.isContextual())
            this.state.name = this.state.name ? this.state.name : "Untitled";
        // for dm
        this.state.name = "Conversation-#";
        const oppositeParticipant = this.getDMParticipant();
        if (oppositeParticipant)
            this.state.name = `${oppositeParticipant?.name}`; // -${oppositeParticipant.user_email}`
    }

    getDMParticipant() {
        const participant = this.state.participants?.find(
            (paricipant) =>
                paricipant.twilio_identity != loggedInUserChatIdentity(),
        );
        // console.log("other participant ", participant)
        return participant || {};
    }

    processingStarted() {
        this.state.isConversationPreparationInProgress = 1;
        if (this.isIndicatorSetToHide()) return;
        this.isCurrentConversation() &&
        (this.didISetCurrentConversationStateToLoading = true) &&
        showConnectingIndicator();
    }

    processingCompleted() {
        this.state.isConversationPreparationInProgress = 0;

        /* to set chat connecting indicator status to false if current conversation was replaced with new current one (fake conversaton) */
        if (
            this.isCurrentConversation() ||
            this.didISetCurrentConversationStateToLoading
        )
            hideConnectingIndicator();
    }

    setHideLoadingIndicatorWith(flag) {
        this.state.hideLoadingIndicator = flag;
    }

    setAssignee(profile_id) {
        this.state.user_profile_id_assignee = profile_id;
    }

    dontShowLoadingIndicators() {
        this.setHideLoadingIndicatorWith(true);
    }

    showLoadingIndicator() {
        this.setHideLoadingIndicatorWith(true);
        showConnectingIndicator();
        this.forceUiUpdate();
    }

    isIndicatorSetToHide() {
        return this.state.hideLoadingIndicator;
    }

    isProcessing() {
        return this.isIndicatorSetToHide()
            ? false
            : this.state.isConversationPreparationInProgress;
    }

    // getChatToken() {
    //     return this.chat_access_token;
    // }

    prepareStatusClassModifier() {
        const errors = ["No Conversation", "offline", "failed"];

        if (errors.includes(this.state.status)) {
            return "prospus-chat-status--offline";
        }
        return "prospus-chat-status--connected";
    }

    setInitialMessagesFetched() {
        this.state.is_initial_messages_fetched = true;
    }

    removeInitialMessagesFetched() {
        this.state.is_initial_messages_fetched = false;
    }

    setStatusToPreparing() {
        this.state.status = "preapring";
        this.processingStarted();
    }

    setStatusToLoadingMessages() {
        this.state.status = "loading messages";
    }

    setStatusToConnecting() {
        this.state.status = "connecting";
    }

    setStatusToConnected() {
        this.state.status = STATUS.CONNECTED;
        this.processingCompleted();
    }

    setStatusReadyToChat() {
        this.state.status = STATUS.CONNECTED;
        this.processingCompleted();
    }

    setStatusToFailed() {
        this.state.status = STATUS.FAILED;
        this.processingCompleted();
    }

    setStatusToOffline() {
        this.state.status = STATUS.OFFLINE;
    }

    updateState(state) {
    }

    isReadyToChat() {
        return this.state.status == STATUS.CONNECTED;
    }

    getStatus() {
        return this.state.status;
    }

    hasTwilioConversationSet() {
        return this.getTwilioConversation();
    }

    hasConversationNotCreated() {
        return !this.getTwilioConversation();
    }

    getTwilioConversation() {
        return this.twilioConversation?.value;
    }

    setTwilioConversation(value) {
        this.twilioConversation.value = value;
    }

    isPlaceHolder() {
        return !this.sid && this.id;
    }

    isNewConversation() {
        return !this.sid;
    }

    setRecentActivePanel(val) {
        this.recent_active_panel = val;
    }

    /*
    1. fake contextual conversation
    2. place holder conversation
    */
    isToShowHint() {
        return (
            !this.areMessagesBuffered() &&
            ((!this.sid && this.id) || this.isFake())
        );
    }

    isFakeEmptyContextualConversation() {
        return this.isContextual() && !this.areMessagesBuffered() && !this.sid;
    }

    async prepare() {
        if (!this.sid)
            return console.log("Empty Conversation..", this.conversationId);
        subscribeWithIdentity(this.auth_user_chat_identity, this);
    }

    setState(newState) {
        this.state = {...this.state, ...newState};
    }

    async onConversationFound(twilioConversation) {
        if (this.hasTwilioConversationSet()) {
            window.logger(
                "twilio connection already exists. Messages will not fetch",
            );
            this.twilioConversation.value = twilioConversation;
            return;
        }

        this.removeInitialMessagesFetched();
        this.setTwilioConversation(twilioConversation);
        //this.state.twilioConversation = twilioConversation;
        this.setUpAndPrepareRequiredFields();

        // if (this.isToSendBoardMessage)
        //     this.sendBoardInvitationMessages()
        //
        // if (this.isToSendGroupMessage)
        //     this.sendGroupInvitationMessages()

        //        this.state.is_clear_messages && await new Promise(resolve => setTimeout(resolve, 2000));
        await this.sendBufferedMessages();
        window.logger("fetch started ", twilioConversation.sid);
        this.fetchMessages();
        //this.state.bufferedMessages = []
        this.state.total_messages =
            await this.getTwilioConversation().getMessagesCount();
        this.registerRequiredHandleraWithConversation();
        this.registerForParticipantEvents();
        this.sendMessageToNotifyWithCurrentUrl();
        // this.setupUrgentRequests();
    }

    // setupUrgentRequests() {
    //     return ;

    //     this.state.urgent_request =
    //         this.getTwilioConversation()?.attributes?.urgent_request ?? [];

    //     useRequest().updateUrgentRequest(this.state.urgent_request);
    // }

    setupUrgentRequestsKaamfu(model_req) {
        if (model_req) {
            model_req.forEach((req) => {
                useRequest().setUrgentRequestByPush(req);
            });
            //useRequest().updateUrgentRequest(this.state.urgent_request)
        } else useRequest().setUrgentRequest([]);
    }

    registerRequiredHandleraWithConversation() {
        if (this.hasAlredyRegisteredForMessageAddedEvent()) return;

        this.listenForParticipantLeft();
        this.listenForParticipantJoined();
        this.listenForNewMessage();
        this.registerForTypeHinting();
        this.registeringHandlersCompleted();
        this.listenForUpdateMessage();
    }

    async fetchTwilioParticipants(is_to_move_scrollbar = false) {
        if (this.hasConversationNotCreated()) return;
        const participants =
            await this.getTwilioConversation().getParticipants();
        // console.log("updating participants lastReadMessageIndex for " , this.getName())
        participants.forEach((participant) => {
            this.updateParticipantLastReadMessageIndex(participant);
        });
        this.updateLastReadUsersForMessages();
        // this.moveScrollBar();
    }

    updateParticipantLastReadMessageIndex(participant) {
        const userDetails = this.findParticipantByIdentity(
            participant.identity,
        );
        if (!userDetails) return;
        userDetails.last_read_message_index = participant.lastReadMessageIndex;
        // console.log( "Updating for participant : ", userDetails , participant.lastReadMessageIndex)
    }

    async fetchMessages() {
        this.setStatusToLoadingMessages();
        this.fetchTwilioParticipants();
        this.unreadMessagesCount =
            await this.getTwilioConversation().getUnreadMessagesCount();
        this.updateUnreadMessagesCount();

        this.getTwilioConversation()
            .getMessages(this.MESSAGES_CHUNK_SIZE)
            .then((messagePaginator) => {
                //  if there are any messages before conversation has loaded. push it at the end.
                const current_messages = this.state.messages;
                this.state.messages = [];
                messagePaginator.items.forEach((message, index) => {
                    this.addNewMessage(
                        message,
                        this.parseTwilioMessageBody(message),
                    );
                });

                if (!this.state.is_clear_messages) {
                    current_messages.forEach((message) => {
                        this.state.messages.push(message);
                    });
                }

                if (this.isCurrentConversation())
                    console.log(
                        "message fetched for conversation ",
                        this.state.name,
                        Date.now(),
                    );

                if (this.MESSAGES_CHUNK_SIZE === messagePaginator.items.length)
                    this.state?.messages?.splice(0, 1);

                this.clearCachedMessages();
                this.setStatusReadyToChat();
                this.setInitialMessagesFetched();
                this.setUnreadMessagesCountFromFetchedMessages();
                this.updateLastReadUsersForMessages();
                this.moveScrollBar();
                this.removeAsArchivedConversation();

                const first_unread_message = this.firstUnReadMessage();
                useConversation().addUnreadConversation(
                    this.unreadMessagesCount,
                    {
                        id: this.id,
                        itemId: this.itemId,
                        name: this.name,
                        author: first_unread_message?.author,
                        type: first_unread_message?.type,
                        lastMessageDateCreated:
                        this.getTwilioConversation().lastMessage
                            ?.dateCreated,
                    },
                );
                this.state.message_fetching_attempt = 0;
            })
            .catch((err) => {
                this.state.message_fetching_attempt += 1;
                if (this.state.message_fetching_attempt >= 2) {
                    console.error("Couldn't fetch messages", err);
                    this.setState({loadingState: "failed"});
                    this.setInitialMessagesFetched();
                    this.setStatusToFailed();
                    return;
                }

                window.logger(
                    "Message fetch failed. Retrying " +
                    this.state.message_fetching_attempt,
                );
                this.fetchMessages();
            });
    }

    loadMoreMessages(
        number_of_messages = 25,
        end_message_index = this.state?.messages?.[0]?.index,
    ) {
        if (
            !end_message_index ||
            this.state.isLoadingMoreMessages ||
            !this.hasTwilioConversationSet()
        )
            return;

        this.state.isLoadingMoreMessages = true;
        this.state.lastMessageIndexFromNewlyLoadedMessages = end_message_index;

        return this.getTwilioConversation()
            .getMessages(number_of_messages, end_message_index)
            .then((messagePaginator) => {
                messagePaginator.items.forEach((message, index) => {
                    if (end_message_index != message.index)
                        this.addNewMessage(
                            message,
                            this.parseTwilioMessageBody(message),
                            true,
                            index,
                        );
                });

                this.setLoadingMoreMessagesTimeOut();
                return true;
            })
            .catch((err) => {
                console.error("Couldn't fetch messages IMPLEMENT RETRY", err);
                return false;
            });
    }

    // getTwillioMessages(number_of_messages = this.MESSAGES_CHUNK_SIZE, end_message_index = null) {
    //     return this.twilioConversation.getMessages(number_of_messages, end_message_index)
    // }

    setLoadingMoreMessagesTimeOut() {
        clearTimeout(this.loadingMoreMessagesTimeOut);

        this.loadingMoreMessagesTimeOut = setTimeout(() => {
            this.state.isLoadingMoreMessages = false;
            this.state.lastMessageIndexFromNewlyLoadedMessages = null;
            clearTimeout(this.loadingMoreMessagesTimeOut);
        }, 600);
    }

    // mute() {
    //     this.isMuted = true;
    // }

    incrementUnreadMessagesCount() {
        this.unreadMessagesCount =
            this.unreadMessagesCount == null ? 0 : this.unreadMessagesCount;
        ++this.unreadMessagesCount;
        this.updateUnreadMessagesCount();
        //this.isMuted = false;
    }

    setUnreadMessagesCountFromFetchedMessages() {
        if (this.unreadMessagesCount != null || !this.state.messages.length)
            return;

        this.unreadMessagesCount = this.getLatestMessageTwilioIndex();
        this.updateUnreadMessagesCount();
    }

    updateUnreadMessagesCount() {
        if (!this.sid) return;
        updateUnreadMessagesCountBy(this.sid, this.unreadMessagesCount);
    }

    getLastMessage() {
        return this.state.messages[this.state.messages.length - 1];
    }

    getLatestMessageTwilioIndex() {
        return this.getLastMessage()?.index;
    }

    sendBoardInvitationMessages() {
        let authUser = this.authUser;
        let names = "";
        let ctr = 1;

        this.participants?.forEach((v) => {
            if (authUser.id != v.id) {
                if (this.participants.find((ov) => v.id === ov.id)) {
                    names += "&#x2022; " + v.name + " <br />";
                }
            }
            ctr++;
        });

        const newMessage =
            authUser.first_name +
            " Created Board : <br /> " +
            authUser.first_name +
            " has added: <br />" +
            names;
        this.sendMessage(newMessage);
    }

    markAllMessagesRead() {
        if (this.unreadMessagesCount == 0 || !this.hasTwilioConversationSet()) {
            return;
        }
        //this is for initial update
        useConversation().addUnreadConversation(0, {
            id: this.id,
            itemId: this.itemId,
            name: this.name,
            lastMessageDateCreated:
            this.getTwilioConversation().lastMessage?.dateCreated,
        });

        this.getTwilioConversation()
            ?.setAllMessagesRead()
            .then((unreadMessagesCount) => {
                this.unreadMessagesCount = unreadMessagesCount;
                this.updateUnreadMessagesCount();
                //final update with real value of 'unreadMessagesCount'
                useConversation().addUnreadConversation(unreadMessagesCount, {
                    id: this.id,
                    itemId: this.itemId,
                    name: this.name,
                    lastMessageDateCreated:
                    this.getTwilioConversation().lastMessage?.dateCreated,
                });
            });
    }

    markMessagesAsRead(is_to_update_authors = false) {
        if (
            !is_to_update_authors &&
            (!this.state.messages?.length || !this.unreadMessagesCount)
        )
            return;

        const lastMessageIndex = this.getLatestMessageTwilioIndex();

        if (lastMessageIndex == null) return;

        this.updateAuthorsLastReadMessageIndexWith(lastMessageIndex);
    }

    updateAuthorsLastReadMessageIndexWith(
        lastMessageIndex,
        isAuthorsMessage = false,
    ) {
        // const unreadMessagesCount = this.unreadMessagesCount;
        // this.unreadMessagesCount = 0

        if (!this.hasTwilioConversationSet()) {
            return;
        }

        this.getTwilioConversation()
            .updateLastReadMessageIndex(lastMessageIndex)
            .then((unreadMessagesCount) => {
                clearTimeout(unread_message_timeout.value);
                unread_message_timeout.value = setTimeout(() => {
                    if (!isAuthorsMessage) {
                        this.unreadMessagesCount = unreadMessagesCount;
                        useConversation().addUnreadConversation(
                            unreadMessagesCount,
                            {
                                id: this.id,
                                itemId: this.itemId,
                                name: this.name,
                                lastMessageDateCreated:
                                this.getTwilioConversation().lastMessage
                                    .dateCreated,
                            },
                        );
                    }

                    clearTimeout(unread_message_timeout.value);
                }, 600);
                this.updateUnreadMessagesCount();
            })
            .catch(() =>
                console.log("not able to update last read message index"),
            );
    }

    lastReadParticipantsForMessageAt(messageAt) {
        const currentMessage = this.state.messages[messageAt];
        const currentMessageIndex = currentMessage?.index;
        const nextMessageIndex = this.state.messages[messageAt + 1]?.index;
        const messageAuthorName = this.getMessageAuthorName(currentMessage);

        /* console.log( "unreadParticipantsForMessageAt: ",  {
                messageAt,
                message:currentMessage.body,
                messageAuthorName,
                message_author_identity: currentMessage.author,
                currentMessageIndex,
                nextMessageIndex
            }); */

        const usersRead = new Set();

        this.twilioParticipants?.forEach((participant) => {
            // const participantName = this.getUserName(participant.identity);
            /* console.log( ` participant `, {
                                        participantName,
                                        participant_identity: participant.identity,
                                        userLastMessageReadIndex : participant.lastReadMessageIndex,
                                        nextMessageIndex,
                                    }) */
            if (participant.last_read_message_index == null) return;
            if (
                participant.last_read_message_index >= currentMessageIndex &&
                (nextMessageIndex == null ||
                    participant.last_read_message_index < nextMessageIndex)
            ) {
                participant.name && usersRead.add(participant.name[0]);
            }
        });

        if (messageAt == this.state.messages.length - 1)
            usersRead.add(loggedInUserName()[0]);

        return Array.from(usersRead);
    }

    updateLastReadUsersForMessages() {
        if (
            this.isLastReadUsersOnMessagesSet ||
            !this.isCurrentConversation() ||
            !this.state.participants?.length ||
            !this.state.messages?.length
        )
            return;
        this.isLastReadUsersOnMessagesSet = true;

        let participants = this.state.participants.filter(
            (participant) =>
                participant.last_read_message_index != null &&
                participant.id != this.getUserId(),
        );
        let currentMessage;
        for (let index = this.state.messages.length - 1; index >= 0; --index) {
            currentMessage = this.state.messages[index];

            participants = participants.filter((participant, index) => {
                if (
                    participant.last_read_message_index >= currentMessage.index
                ) {
                    const set = new Set(currentMessage.last_read_by_users);
                    participant.name && set.add(participant.name[0]);
                    currentMessage.last_read_by_users = [...set];
                    return false;
                }
                return true;
            });
            if (!participants.length) return;
        }
        this.moveScrollBar();
    }

    updateMyLastReadMessage(lastIndexFrom = null) {
        const authorNameFirstChar = this.authorName()[0];
        if (!authorNameFirstChar) return;

        if (lastIndexFrom == null)
            lastIndexFrom = this.state.messages.length - 2;

        for (let index = lastIndexFrom; index >= 0; --index) {
            const index = this.state.messages.last_read_by_users?.filter(
                (userNameFirstChar) => authorNameFirstChar == userNameFirstChar,
            );

            if (index >= 0) {
                this.state.messages.last_read_by_users.splice(index, 1);
                break;
            }
        }
    }

    removeUserFromMessageLastReadUsers(participant) {
        if (this.state.messages.length == 0) return;
        for (let index = this.state.messages.length - 1; index >= 0; --index) {
            const message = this.state.messages[index];
            const previousMessage = this.state.messages[index - 1];
            if (participant?.last_read_message_index >= message.index) {
                const set = new Set(message.last_read_by_users);
                set.delete(participant.name[0]);
                message.last_read_by_users = [...set];
                /* if previous message  */
                if (
                    !(
                        participant.last_read_message_index >=
                        previousMessage?.index
                    )
                )
                    return;
            }
        }
    }

    addUserToMessageLastReadUsers(participant) {
        const lastIndex = this.state.messages.length - 1;
        let message = this.state.messages[lastIndex];
        let nextMessage;
        const participantLastReadMessageIndex =
            participant?.last_read_message_index;
        /* if last message is read, update */
        if (message && participantLastReadMessageIndex >= message.index) {
            const set = new Set(message.last_read_by_users);
            set.add(participant.name[0]);
            message.last_read_by_users = [...set];
            return;
        }
        if (lastIndex == 0) return;
        // check for all messages at indices [0.. length-2]
        for (let index = lastIndex - 2; index >= 0; --index) {
            message = this.state.messages[index];
            nextMessage = this.state.messages[index + 1];

            if (
                message &&
                participantLastReadMessageIndex < nextMessage.index &&
                participantLastReadMessageIndex >= message.index
            ) {
                const set = new Set(message.last_read_by_users);
                set.add(participant.name[0]);
                message.last_read_by_users = [...set];
                return;
            }
        }
    }

    registerForParticipantEvents() {
        this.getTwilioConversation().on("participantJoined", (participant) =>
            this.updateParticipantListIfNewUserInvited(participant),
        );

        this.getTwilioConversation().on("participantUpdated", (event) => {
            // console.log("participantUpdated for : ", this.getUserName(event.participant.identity), event.participant.lastReadMessageIndex)
            const participantUpdated = event.participant;
            const participant = this.findParticipantByIdentity(
                participantUpdated.identity,
            );
            this.removeUserFromMessageLastReadUsers(participant);
            this.updateParticipantLastReadMessageIndex(participantUpdated);
            // participant = this.findParticipantByIdentity(participantUpdated.identity);
            // update read messages users list only for other participants
            if (this.getUserIdentity() != participantUpdated.identity)
                this.addUserToMessageLastReadUsers(participant);
            // this.updateLastReadUsersForMessages()
        });
    }

    async updateParticipantListIfNewUserInvited(participant) {
        if (this.isParticipantNew(participant)) {
            //  const conversationDetails = await fetchConversationById(this.id);
            //  this.setConversationVariables(conversationDetails);
            //  this.forceUiUpdate();
        }
    }

    listenForUpdateMessage() {
        this.getTwilioConversation().on("messageUpdated", (res) => {
            if (
                useAppSateScreenShot().hasScreenShotStarted &&
                res.updateReasons?.includes("body")
            ) {
                useAppSateScreenShot().trackDeletedImages();
            }
            this.addNewMessage(
                res.message,
                this.parseTwilioMessageBody(res.message),
            );
            //this.updateMessagesMap(res.message, true, res.message.body, 'text', null)
        });
    }

    listenForParticipantLeft() {
        this.getTwilioConversation().on(
            "participantLeft",
            async (participant) => {
                if (participant.identity == loggedInUserChatIdentity()) {
                    try {
                        const {data} = await axios.get(
                            `/conversations/${this.id}`,
                        );

                        if (!data?.id) return;

                        useConversation().removeAndUpdateActiveIndexById(
                            this.id,
                        );
                    } catch (e) {
                        if (e?.response?.data?.type) {
                            this.setAsArchivedConversation();
                            this.setTwilioConversation(null);
                            this.removeAllParticipants();
                        } else {
                            useConversation().removeAndUpdateActiveIndexById(
                                this.id,
                            );
                        }
                        useUserStore().decreaseTwilioConversationCount();
                    }
                }

                this.updateParticipantRemovedStatus(participant.identity);
            },
        );
    }

    listenForParticipantJoined() {
        this.getTwilioConversation().on("participantJoined", (participant) => {
            this.updateParticipants(this.id);
        });
    }

    checkTwilioUpdateCondition(res) {
        const reasons = res?.updateReasons;
        const active_conversation = useConversation().active;

        return (
            reasons[0] === "attributes" ||
            (reasons.length === 1 &&
                reasons.find((item) => item === "lastMessage") &&
                active_conversation.id !== this.id)
        );
    }

    async updateParticipants(participants = []) {
        if (!participants.length) {
            const {data} = await axios.get(`/conversations/${this.id}`);

            if (!data?.participants) return;

            participants = data?.participants;
        }

        const new_participants = [];
        participants.forEach((participant) => {
            const current_participant = this.getParticipantByIdentity(
                participant.twilio_identity,
            );
            if (current_participant) {
                participant.last_read_message_index =
                    current_participant.last_read_message_index;
            }
            new_participants.push(participant);
        });
        this.state.participants = new_participants;
    }

    listenForNewMessage() {
        this.getTwilioConversation().on("messageAdded", (message) => {
            const isAuthorsMessage = this.isAuthorsMessage(message);
            const has_images = checkImageExists(message.body);

            const isActiveTab =
                this.isCurrentConversationAndChatPanelOpened() &&
                !(
                    this.isStatusInActive() ||
                    window.isAppInActive ||
                    is_inactive.value
                );
            const is_end_assignment = useConversation().is_end_assignment;
            /*
             *    if tab is active and same user it means message is already pushed. Let wait for sometime for
             *    twillio message respond with index. So, in processNewMessage function it will check for
             *    duplicate.
             * */

            if (is_end_assignment || isAuthorsMessage) {
                useConversation().setIsEndAssignment(false);
                timeOutHelper(() => {
                    this.processNewMessage(
                        message,
                        isAuthorsMessage,
                        isActiveTab,
                    );
                }, 2000);
                return;
            }
            this.processNewMessage(message, isAuthorsMessage, isActiveTab);

            if (has_images && useAppSateScreenShot().hasScreenShotStarted) {
                const result = grabImageUrls(message.body);

                useAppSateScreenShot().slider_images = [
                    ...useAppSateScreenShot().slider_images,
                    ...result,
                ];
            }
        });
    }

    processNewMessage(message, isAuthorsMessage, isActiveTab) {
        const messageIndex = this.getMessageIndex(message);
        const currentMessageIndex = this.state.messages.length;
        const is_author_message_exist = isAuthorsMessage && messageIndex !== -1;
        const parsedBody = this.parseTwilioMessageBody(message);
        if ([MESSAGE.STATE, MESSAGE.META].includes(parsedBody?.type)) {
            if (isAuthorsMessage) {
                this.updateAuthorsLastReadMessageIndexWith(
                    message.index,
                    isAuthorsMessage,
                );
                this.trashTwilioMessage(message);
            } else this.updateUserStatus(message, parsedBody);
            return;
        }

        // add only if it's not authors message
        if (is_author_message_exist) {
            this.messagesNotProcessed.push(message);
            this.updateMessage(
                this.getMessageFromNotProcessedByIndex(message.index),
            );
        } else this.addNewMessage(message, parsedBody, is_author_message_exist);

        if (isAuthorsMessage || isActiveTab)
            this.updateAuthorsLastReadMessageIndexWith(
                message.index,
                isAuthorsMessage,
            );
        // is_to_update_authors
        else if (!isAuthorsMessage)
            this.handleMessageReceivedOnNonCurrentConversation(
                this.state.messages[currentMessageIndex],
                isAuthorsMessage,
            );

        updateConversationListOrderingWhenConversationReceivedAMessage(
            this.id,
            is_author_message_exist,
        );
    }

    parseTwilioMessageBody(twilioMessage) {
        if (this.isMessageMedia(twilioMessage)) return;

        try {
            const parsedBody = JSON.parse(twilioMessage.body);
            /**
             * Handle non-exception-throwing cases:
             * Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
             * but... JSON.parse(null) returns null, and typeof null === "object",
             * so we must check for that, too. As null is falsey, so this suffices:
             */
            if (parsedBody && typeof parsedBody === "object") return parsedBody;
        } catch (err) {
        }
    }

    handleMessageReceivedOnNonCurrentConversation(message) {
        // console.log("handleMessageReceivedOnNonCurrentConversation: ", twilioMessage.body);
        // non authors
        this.incrementUnreadMessagesCount();

        useConversation().addUnreadConversation(this.unreadMessagesCount, {
            id: this.id,
            itemId: this.itemId,
            name: this.name,
            lastMessageDateCreated:
            this.getTwilioConversation().lastMessage?.dateCreated,
            author: message.author,
            type: message.type,
        });

        if (this.isStatusInActive() || window.isAppInActive) {
            const author = this.getMessageAuthorName(message);
            //  const messageText = this.prepareDisplayableTextForMessage(message);
            window.notifyWith(
                this.getName(),

                this.isDm() ? message.body : `${author} : ${message.body}`,

                () => {
                    // console.log("callback... on notificaiton click")
                    useConversation().openConversationById(this.id);
                    this.markMessagesAsRead();
                },
            );
        }
    }

    prepareDisplayableTextForMessage(message) {
        if (!message) return "";

        if (this.isMessageMedia(message)) return "Shared Media";

        switch (message.type) {
            case MESSAGE.TEXT:
            case MESSAGE.NAVIGATION:
            case MESSAGE.META:
                return message.body;
            case MESSAGE.TIMER:
                return message?.data?.message;
            case MESSAGE.CONVERSATION:
                return "Conversation " + message?.data?.sub_type;
            case MESSAGE.ACTION:
                switch (message?.data?.sub_type) {
                    case "item_moved":
                        return `Item moved from ${message?.data?.old_item_group?.name} To ${message?.data?.new_item_group?.name} `;
                }
                return "";
            case MESSAGE.INVITE:
            case MESSAGE.UNINVITE:
            case MESSAGE.ASSIGN:
            case MESSAGE.UNASSIGN:
            case MESSAGE.ATTENTION:
            case MESSAGE.ATTENTION_CLOSED:
            case MESSAGE.REQUEST_STATUS:
            case MESSAGE.REQUEST_STATUS_CLOSED:
            case MESSAGE.SUPPORT:
            case MESSAGE.SUPPORT_CLOSED:
                let user_string = "";
                if (message?.data?.user_profiles)
                    user_string = message?.data?.user_profiles
                        .map((profile) => profile?.name)
                        .join(", ")
                        .replace(/, ([^,]*)$/, " and $1");

                return `${message?.data?.message} ${user_string}`.trim();
            default:
                return message.body;
        }
    }

    getMessageActionText(parsedBody) {
        return this.prepareDisplayableTextForMessage({
            data: parsedBody,
            ...parsedBody,
        });
    }

    addNewMessageToBeReplaced(message, type = MESSAGE.TEXT) {
        const {name, avatar} = this.getMessageAuthorDetail({
            author: this.getUserIdentity(),
        });
        let attributes;

        try {
            attributes = message.attributes
                ? JSON.parse(message.attributes)
                : null;
        } catch (error) {
            attributes = null;
        }

        if (
            attributes &&
            attributes.hasOwnProperty("replyTo") &&
            attributes.replyTo.dateCreated
        ) {
            attributes.replyTo.dateCreated = new Date(
                attributes.replyTo.dateCreated,
            );
            attributes.replyTo.sent_time = this.messageTime(attributes.replyTo);
        }

        const newMessage = {
            sid: Date.now(),
            ...message,
            ...this.prepareMessageTypeFlagsWithType(type, message),
            // type: isMedia ? 'media' : 'text',
            // body: isMedia ? '' :  window.replaceLinksWithATags(newMessage.body),
            author: this.getUserIdentity(),
            author_name: name,
            author_avatar: avatar,
            attributes: attributes,
            is_author: true,
            sent_time: this.messageTime(new Date()),
            dateCreated: new Date(),
            key: this.getNextIndex(), //Date.now(),
            has_same_author_as_previous_message: false,
            is_previous_message_event: false,
            is_in_same_minutue_as_previous_message: false,
            // index: messageIndex, // no need of -1,here as it's before adding new message
            last_read_by_users: [],
            is_sending: true,
            is_failed: false,
        };

        this.updateMessageAuthorAndTimestamp(
            this.state.messages.length,
            newMessage,
        );
        this.checkPreviousNextEventMessage(
            this.state.messages.length,
            newMessage,
        );
        newMessage.body = this.prepareDisplayableTextForMessage(newMessage);
        this.state.messages.push(newMessage);

        // this.updateMyLastReadMessage()
        this.moveScrollBar();

        return newMessage;
    }

    addNewMessage(
        newMessage,
        parsedBody = null,
        isToSetFocusOnTextArea = true,
        custom_index = undefined,
    ) {
        if (this.isMessageMedia(newMessage))
            return this.updateMessagesMap(
                newMessage,
                isToSetFocusOnTextArea,
                "",
                "media",
            );

        /* Delete/Clear if message is empty  */
        if (!newMessage.body) return; // this.trashTwilioMessage(newMessage);

        if (!parsedBody) {
            return this.updateMessagesMap(
                newMessage,
                isToSetFocusOnTextArea,
                window.replaceLinksWithATags(newMessage.body),
                MESSAGE.TEXT,
                null,
                custom_index,
            );
        }

        // const parsedBody =  newMessage.body //JSON.parse(newMessage.body);

        /* if (parsedBody && typeof parsedBody !== "object") {
                throw "Parsed, but not Meta data";
            } */

        switch (parsedBody.type) {
            // to remove old undeleted status message if any left while fetching messages
            case MESSAGE.STATUS:
            case MESSAGE.META:
                // console.log("trashing message ", newMessage.body);
                this.trashTwilioMessage(newMessage);
                break;
            case MESSAGE.STATE:
            case MESSAGE.INVITE:
            case MESSAGE.UNINVITE:
            case MESSAGE.ASSIGN:
            case MESSAGE.UNASSIGN:
            case MESSAGE.ATTENTION:
            case MESSAGE.ATTENTION_CLOSED:
            case MESSAGE.REQUEST_STATUS:
            case MESSAGE.REQUEST_STATUS_CLOSED:
            case MESSAGE.SUPPORT:
            case MESSAGE.SUPPORT_CLOSED:
                //  const body = prepareBreadCumbForBoardAndItem(parsedBody.board_id, parsedBody.item_id);
                // console.log(parsedBody);
                this.addProfileData(parsedBody);
                this.updateMessagesMap(
                    newMessage,
                    isToSetFocusOnTextArea,
                    this.getMessageActionText(parsedBody),
                    parsedBody.type,
                    parsedBody,
                    custom_index,
                );
                break;
            case MESSAGE.IMAGE:
            case MESSAGE.VIDEO:
                this.updateMessagesMap(
                    newMessage,
                    isToSetFocusOnTextArea,
                    parsedBody.url,
                    parsedBody.type,
                    parsedBody,
                    custom_index,
                );
                break;
            case MESSAGE.PAYMENT:
                // console.log("payment", parsedBody, newMessage.body);
                this.updateMessagesMap(
                    newMessage,
                    isToSetFocusOnTextArea,
                    "Payment Link shared",
                    parsedBody.type,
                    parsedBody,
                    custom_index,
                );
                break;
            case MESSAGE.NAVIGATION:
                this.updateMessagesMap(
                    newMessage,
                    isToSetFocusOnTextArea,
                    "Navigated",
                    parsedBody.type,
                    parsedBody,
                    custom_index,
                );
                break;
            case MESSAGE.CONVERSATION:
                this.updateMessagesMap(
                    newMessage,
                    isToSetFocusOnTextArea,
                    this.getMessageActionText(parsedBody),
                    parsedBody.type,
                    parsedBody,
                    custom_index,
                );
                break;
            case MESSAGE.TIMER:
                parsedBody.message = useTimeTrackerStore().getEventMessage(
                    parsedBody?.sub_type,
                );
                parsedBody.action = useTimeTrackerStore().getEventAction(
                    parsedBody?.sub_type,
                );
                this.updateMessagesMap(
                    newMessage,
                    isToSetFocusOnTextArea,
                    parsedBody.message,
                    parsedBody.type,
                    parsedBody,
                    custom_index,
                );
                break;
            case MESSAGE.ACTION:
                this.updateMessagesMap(
                    newMessage,
                    isToSetFocusOnTextArea,
                    this.getMessageActionText(parsedBody),
                    parsedBody.type,
                    parsedBody,
                    custom_index,
                );
                break;
            default:
                //console.log('unknow type, message ignored', newMessage.body);
                break;
        }
    }

    updateMessagesMap(
        newMessage,
        isToSetFocusOnTextArea = true,
        body,
        type = MESSAGE.TEXT,
        data = null,
        custom_index = undefined,
    ) {
        //console.log('updateMessagesMap newMessage', newMessage)
        let messageIndex = this.getMessageIndex(newMessage);
        let isToReplace = true;
        /* if not, it's new message insert at the end */

        if (messageIndex == -1) {
            isToReplace = false;
            messageIndex = custom_index ?? this.state.messages.length;
        } else {
            const tmpBody = this.state.messages[messageIndex].body;
            if (type == MESSAGE.MEDIA) body = tmpBody;
        }

        const {messages} = this.state;

        // const isMedia = this.isMessageMedia(newMessage);
        let attributes;

        try {
            attributes = newMessage.attributes
                ? JSON.parse(newMessage.attributes)
                : null;
        } catch (error) {
            attributes = null;
        }

        const {name, avatar} = this.getMessageAuthorDetail(newMessage);

        if (
            attributes &&
            attributes.hasOwnProperty("replyTo") &&
            attributes.replyTo.dateCreated
        ) {
            attributes.replyTo.dateCreated = new Date(
                attributes.replyTo.dateCreated,
            );
            attributes.replyTo.sent_time = this.messageTime(attributes.replyTo);
        }

        const message = {
            sid: newMessage.sid,
            body,
            ...this.prepareMessageTypeFlagsWithType(type),
            // type: isMedia ? 'media' : 'text',
            // body: isMedia ? '' :  window.replaceLinksWithATags(newMessage.body),
            media_type: this.getFileType(newMessage?.media?.state?.contentType),
            attributes: attributes,
            author: newMessage.author,
            is_author: this.isAuthorsMessage(newMessage),
            author_name: name,
            author_avatar: avatar,
            created_at: new Date(newMessage.dateCreated),
            dateCreated: newMessage.dateCreated,
            sent_time: this.messageTime(newMessage),
            key: this.getNextIndex(), //Date.now(),
            has_same_author_as_previous_message: false,
            is_previous_message_event: false,
            is_in_same_minutue_as_previous_message: false,
            index: newMessage.index, // no need of -1,here as it's before adding new message
            is_deleted: attributes?.status === "deleted",
            is_failed: attributes?.status === "failed",
            last_read_by_users: [],
            data,
            updateBody: (str) => newMessage.updateBody(str),
            updateAttributes: (val) => newMessage.updateAttributes(val),
        };

        //let messages_length = messages.length

        this.updateMessageAuthorAndTimestamp(messageIndex, message);
        this.checkPreviousNextEventMessage(messageIndex, message);
        messages.splice(messageIndex, isToReplace ? 1 : 0, message);

        if (type != MESSAGE.MEDIA /* !isMedia */) {
            // this.state.messages = messages;
            this.moveScrollBar(isToSetFocusOnTextArea);
            return;
        }

        // fetch file url and update the corresponsidng value in map
        // it has to be index(length-1)
        // const indexToUpdateMessageBody = messages.length - 1;

        if (type === MESSAGE.IMAGE || type === MESSAGE.VIDEO) {
            messages.splice(messageIndex, 1, message);
            this.moveScrollBar(isToSetFocusOnTextArea);
        }
        // newMessage
        //   .media
        //   .getContentTemporaryUrl()
        //   .then((url) => {
        //     message.body = url;
        //     message.key = Date.now();
        //     // messages.splice(indexToUpdateMessageBody, 1, message);
        //     messages.splice(messageIndex, 1, message);
        //     this.moveScrollBar(isToSetFocusOnTextArea);
        //   });
    }

    getNextIndex() {
        return ++this.index_key;
    }

    updateMessage(newMessage, isToSetFocusOnTextArea = true) {
        const messageIndex = this.getMessageIndex(newMessage);
        if (messageIndex == -1)
            return console.log("Not message found to update");
        const message = this.state.messages[messageIndex];
        message.sid = newMessage.sid;
        message.index = newMessage.index;
        message.updateBody = (str) => newMessage.updateBody(str);
        message.updateAttributes = (val) => newMessage.updateAttributes(val);

        let attributes;

        try {
            attributes = newMessage.attributes
                ? JSON.parse(newMessage.attributes)
                : null;
        } catch (error) {
            attributes = null;
        }

        if (
            attributes &&
            attributes.hasOwnProperty("replyTo") &&
            attributes.replyTo.dateCreated
        ) {
            attributes.replyTo.dateCreated = new Date(
                attributes.replyTo.dateCreated,
            );
            attributes.replyTo.sent_time = this.messageTime(attributes.replyTo);
        }

        message.attributes = attributes;

        if (!this.isMessageMedia(message)) return;

        message.body = message.data.url;
        //message.type = message.data.type;
        message.key = Date.now();
        this.state.messages.splice(messageIndex, 1, message);
        this.moveScrollBar(isToSetFocusOnTextArea);
        // newMessage
        //   .media
        //   .getContentTemporaryUrl()
        //   .then((url) => {
        //     message.body = url;
        //     message.key = Date.now();
        //     // messages.splice(indexToUpdateMessageBody, 1, message);
        //     this.state.messages.splice(messageIndex, 1, message);
        //     this.moveScrollBar(isToSetFocusOnTextArea);
        //   });
    }

    prepareMessageTypeFlagsWithType(type, message = null) {
        return {
            type: message ? message?.data?.type : type,
            is_media: this.isMessageMedia({type: type, data: message?.data}),
            is_text: type == MESSAGE.TEXT,
            is_state: type == MESSAGE.STATE,
            is_payment: type == MESSAGE.PAYMENT,
            is_invite: type == MESSAGE.INVITE,
            is_uninvite: type == MESSAGE.UNINVITE,
            is_attention: type == MESSAGE.ATTENTION,
            is_attention_closed: type == MESSAGE.ATTENTION_CLOSED,
            is_request_status: type == MESSAGE.REQUEST_STATUS,
            is_request_status_closed: type == MESSAGE.REQUEST_STATUS_CLOSED,
            is_support: type == MESSAGE.SUPPORT,
            is_support_closed: type == MESSAGE.SUPPORT_CLOSED,
            is_assign: type == MESSAGE.ASSIGN,
            is_unassign: type == MESSAGE.UNASSIGN,
            is_navigation: type == MESSAGE.NAVIGATION,
            is_timer: type == MESSAGE.TIMER,
            is_conversation: type == MESSAGE.CONVERSATION,
            is_action: type == MESSAGE.ACTION,
        };
    }

    isMessageDuplicate(newMessage) {
        return this.getMessageIndex(newMessage) > -1;
    }

    getMessageIndex(message) {
        if (!message) return -1;
        return this.state.messages.findIndex(
            (msg) => msg.index == message?.index || msg.sid == message?.sid,
        );
    }

    getMessageBySid(message) {
        return this.state.messages.find((msg) => msg.sid == message.sid);
    }

    isFetchedInitialMessage() {
        return this.state.is_initial_messages_fetched;
    }

    updateMessageAuthorAndTimestamp(messageIndex, message) {
        if (messageIndex == 0) {
            return;
        }

        const previous_message = this.getMessages()[messageIndex - 1];
        if (!previous_message) {
            return;
        }
        message.has_same_author_as_previous_message =
            message.author == previous_message.author;
        message.is_in_same_minutue_as_previous_message = !this.diff_minutes(
            message.dateCreated,
            previous_message.dateCreated,
        );
    }

    checkPreviousNextEventMessage(messageIndex, message) {
        if (messageIndex == 0) return;

        const previous_message = this.getMessages()[messageIndex - 1];
        message.is_previous_message_event =
            this.isMessageEvent(previous_message);
    }

    isMessageEvent(message) {
        return (
            message?.is_assign ||
            message?.is_invite ||
            message?.is_unassign ||
            message?.is_uninvite ||
            message?.is_attention ||
            message?.is_attention_closed ||
            message?.is_request_status ||
            message?.is_request_status_closed ||
            message?.is_support ||
            message?.is_support_closed
        );
    }

    isMessageUrgentRequest(message) {
        return (
            message?.is_attention ||
            message?.is_attention_closed ||
            message?.is_request_status ||
            message?.is_request_status_closed ||
            message?.is_support ||
            message?.is_support_closed
        );
    }

    hasAlredyRegisteredForMessageAddedEvent() {
        return this.isRegisterdForMessageAddedEvent;
    }

    registeringHandlersCompleted() {
        this.isRegisterdForMessageAddedEvent = true;
    }

    isCurrentConversation() {
        return getCurrentConversationId() == this.id;
    }

    isCurrentConversationAndChatPanelOpened() {
        return (
            this.isCurrentConversation() && useAppState().is_conversation_open
        );
    }

    forceUiUpdate() {
        this.updateLastReadUsersForMessages();
        this.prepareParticipantsStatus();
        // this.updateUserStatusIndicators();
        // this.fetchTwilioParticipants();
        // this.scrollBarHandler();
    }

    moveScrollBar(isToSetFocusOnTextArea = true) {
        if (this.isCurrentConversation() && this.scrollBarHandler) {
            this.scrollBarHandler(isToSetFocusOnTextArea);
        }
    }

    isMessageMedia(message) {
        return (
            message?.type === MESSAGE.IMAGE ||
            message?.type === MESSAGE.VIDEO ||
            (message?.type == MESSAGE.STATE &&
                (message?.data?.type === MESSAGE.IMAGE ||
                    message?.data?.type === MESSAGE.VIDEO))
        );
    }

    trashTwilioMessage(twilioMessage, timeOut = 1000) {
        if (!this.isAuthorsMessage(twilioMessage)) return; // console.log("can't be trashed im't not author");

        const timeOutHandler = setTimeout(() => {
            clearTimeout(timeOutHandler);

            if (this.isTwilioMessageTemporary(twilioMessage)) {
                // console.log("Deleting twilio message : ", { name:  this.getName(), body: twilioMessage.body});
                twilioMessage.remove();
            }
        }, timeOut);
    }

    isTwilioMessageTemporary(twilioMessage) {
        const parsedBody = this.parseTwilioMessageBody(twilioMessage);

        if (!parsedBody) return false;

        return (
            parsedBody.type == MESSAGE.STATUS || parsedBody.type == MESSAGE.META
        );
    }

    /**
     *
     * @param FormData Or string message
     * @returns
     */
    async sendToConversation(message, attr = null) {
        useConversation().updateConnectionStatusIfNotConnected();
        return this.getTwilioConversation()
            ?.sendMessage(message, attr)
            .then((res) => res)
            .catch((e) => {
                console.log(
                    `sendToconversation:: Message sending failed: ${e.code} : ${e.message}`,
                );
                throw e;
            });
    }

    getMessageFromNotProcessedByIndex(messageIndex) {
        const index = this.messagesNotProcessed.findIndex(
            (msg) => msg.index == messageIndex,
        );
        if (index == -1) return;
        return this.messagesNotProcessed.splice(index, 1)[0];
    }

    sendMessage(message, attr = null) {
        message = message.trim();
        this.setMessageTyped("");

        if (!message) return;

        const new_attr = attr ? JSON.stringify(attr) : null;

        if (this.hasConversationNotCreated() || this.areMessagesBuffered())
            return this.sendToBuffer(
                {body: message, attributes: new_attr},
                MESSAGE.TEXT,
            );
        //return this.sendToBuffer({body: message, attributes: attr}, 'text');

        // const object_attr = attr ? {
        //     replyTo: {
        //         sent_time: attr.sent_time,
        //         author_name: attr.author_name,
        //         body: attr.body
        //     }
        // } : null

        const newMessage = this.addNewMessageToBeReplaced(
            {
                body: window.replaceLinksWithATags(message),
                attributes: new_attr,
            },
            "text",
        );

        this.sendToConversation(message, new_attr)
            .then((messageIndex) => {
                newMessage.index = messageIndex;
                this.updateLastReadUsersForMessages();
            })
            .catch((ex) => {
                console.log(" failed to send failed to send message");
            });
        //this.clearTypingTimeOutHandler();
        this.updateConversationLastMessageSentAtWith(message);
    }

    sendJSONData(data, type = "state") {
        if (!data) return;
        const body = prepareBreadCumbForBoardAndItem(
            data.board_id,
            data.item_id,
        );
        if (this.hasConversationNotCreated() || this.areMessagesBuffered())
            return this.sendToBuffer(
                {
                    body,
                    data,
                },
                type,
            );

        const pre_insert_message = this.addNewMessageToBeReplaced(
            {body, data},
            type,
        );
        const ret_val = this.sendToConversation(JSON.stringify(data)).then(
            (messageIndex) => {
                pre_insert_message.index = messageIndex;
                const new_message =
                    this.getMessageFromNotProcessedByIndex(messageIndex);
                this.updateMessage(new_message);
                this.updateLastReadUsersForMessages();

                return this.getMessageIndex(new_message);
            },
        );
        this.updateConversationLastMessageSentAtWith("Shared a state");

        return ret_val;
    }

    sendPaymentLink(invoice) {
        const data = {
            type: MESSAGE.PAYMENT,
            invoice_id: invoice.id,
            expires_at: invoice.expires_at,
        };

        if (this.hasConversationNotCreated() || this.areMessagesBuffered())
            return this.sendToBuffer(
                {
                    body,
                    data,
                },
                MESSAGE.PAYMENT,
            );

        const newMessage = this.addNewMessageToBeReplaced(
            {body: "Payment Link Shared", data},
            data.type,
        );
        this.sendToConversation(JSON.stringify(data)).then((messageIndex) => {
            newMessage.index = messageIndex;
            this.updateMessage(
                this.getMessageFromNotProcessedByIndex(messageIndex),
            );
            this.updateLastReadUsersForMessages();
        });
    }

    async sendMultiMediaMessage(fileOrBlob) {
        const previewUrl = URL.createObjectURL(fileOrBlob);
        if (this.hasConversationNotCreated())
            return this.sendToBuffer(
                {
                    body: previewUrl,
                    media: fileOrBlob,
                    media_type: this.getFileType(fileOrBlob.type),
                },
                MESSAGE.MEDIA,
            );

        const newMessage = this.addNewMessageToBeReplaced(
            {
                body: previewUrl,
                media_type: this.getFileType(fileOrBlob.type),
            },
            MESSAGE.MEDIA,
        );
        this.sendFile(fileOrBlob).then((messageIndex) => {
            newMessage.index = messageIndex;
            this.updateMessage(
                this.getMessageFromNotProcessedByIndex(messageIndex),
            );
            this.updateLastReadUsersForMessages();
        });
        this.updateConversationLastMessageSentAtWith("Shared a file");
    }

    getFileType(type) {
        if (/video/i.test(type)) return "video";
        else if (/image/i.test(type)) return "image";

        return null;
    }

    sendFile(fileOrBlob) {
        const formData = new FormData();
        formData.append("file", fileOrBlob);
        return this.sendToConversation(formData);
    }

    isStatusActive() {
        return this.myCurrentStatus == STATUS.ACTIVE;
    }

    isStatusAbsent() {
        return this.myCurrentStatus == STATUS.ABSENT;
    }

    isStatusInActive() {
        return this.myCurrentStatus == STATUS.INACTIVE;
    }

    markAsActive() {
        this.myCurrentStatus = STATUS.ACTIVE;
    }

    markAsInactive() {
        this.myCurrentStatus = STATUS.INACTIVE;
    }

    markAsAbsent() {
        this.myCurrentStatus = STATUS.ABSENT;
    }

    notifyAsActive() {
        if (this.isStatusActive()) return;

        this.markMessagesAsRead();
        /* if state moving from  InActive -> Active */

        /*
         *  methods toggleUserOfInactivity and sendTemporaryNotificationData are not in use therefore it's commented.
         *  Earlier these functions are called after 25sec(INACTIVE_USER_TIME_THRESHOLD = 25000). but because of this, messages are not marked as read instantly.
         *  Therefore this delay is reduced to 4sec. if in future these methods are required. Please make sure it called
         *  with more delay.
         *
         * */

        /*if (this.isStatusInActive())
            this.toggleUserOfInactivity(false);*/

        // console.log(`notifyAsActive - ${this.getName()} : active`);
        this.markAsActive();

        // this.sendTemporaryNotificationData();
    }

    notifyAsInActive() {
        // if user is alredy absent don't make it as Inactive,
        if (this.isStatusAbsent() || this.isStatusInActive()) return;
        this.toggleUserOfInactivity(true);
        // console.log(`notifyAsInActive - ${this.getName()} : inactive`);
        this.markAsInactive();

        //  this.sendTemporaryNotificationData();
    }

    notifyAsAbsent() {
        if (this.isStatusAbsent()) return;
        // console.log(`notifyAsAbsent - ${this.getName()} : absent` );
        this.markAsAbsent();
        this.sendTemporaryNotificationData();
    }

    sendTemporaryNotificationData() {
        const data = {
            type: MESSAGE.STATUS,
            storage: "temporary",
            status: this.myCurrentStatus,
        };

        this.sendToConversation(JSON.stringify(data));
    }

    sendMessageToNotifyWithCurrentUrl() {
        return;
        if (
            !this.isCurrentConversation() ||
            this.hasCurrentUrlNotifcationSentTimeNotExpired()
        )
            return;

        const message = JSON.stringify({
            type: "meta",
            current_url: window.location.href,
        });
        this.sendToConversation(message);
    }

    hasCurrentUrlNotifcationSentTimeNotExpired() {
        const now = new Date();

        if (!this.lastCurrentUrlNotificationSentAt) {
            this.lastCurrentUrlNotificationSentAt = now;
            return false;
        }

        const diffInSeconds = Math.floor(
            (now - this.lastCurrentUrlNotificationSentAt) / 1000,
        );
        if (diffInSeconds > 10) return false;

        return true;
    }

    registerForTypeHinting() {
        this.getTwilioConversation().on("typingStarted", (member) =>
            this.userStartedTyping(member),
        );
        this.getTwilioConversation().on("typingEnded", (member) =>
            this.userStoppedTyping(member),
        );
    }

    findParticipantByIdentity(userIdentity) {
        return this.state.participants?.find(
            (paricipant) => paricipant.twilio_identity == userIdentity,
        );
    }

    notifyTyping() {
        if (this.hasConversationNotCreated()) return;

        this.getTwilioConversation().typing();

        /* if( !this.typingThrottler)
                this.typingThrottler = throttle( () => this.twilioConversation.typing(), 500);

            this.typingThrottler(); */
        /*
            //console.log("im typing...")
            this.userTypedLastAt = new Date();
            this.setUpAutoSendingTypingStatusForTimeoutDuration(); */
    }

    // setUpAutoSendingTypingStatusForTimeoutDuration() {
    //     // message deleted, don't stop here
    //     // console.log("type message :", this.getMesssageTyped())
    //     if (!this.getMesssageTyped()?.length) return this.clearTypingTimeOutHandler();

    //     const diff = new Date() - this.userTypedLastAt;
    //     const seconds = Math.floor(diff / 1000);

    //     this.clearTypingTimeOutHandler();

    //     if (seconds > 60) {
    //         this.userTypedLastAt = null;
    //         if (this.isCurrentConversation()) this.toggleUserOfInactivity(true);
    //         return;
    //     }
    //     // for every 3 seconds keep updating
    //     this.typingTimeOutHandler = setTimeout(() => {
    //         this.setUpAutoSendingTypingStatusForTimeoutDuration();
    //     }, 1000);

    //     this.notifyTyping();
    // }

    // clearTypingTimeOutHandler() {
    //     clearTimeout(this.typingTimeOutHandler);
    // }

    userStartedTyping(participant) {
        return this.updateUserStatus(
            {author: participant.identity},
            {status: STATUS.TYPING},
        );
        /* here participant is of twilio's Member record, so it's identity not twilio_identity(it's our internal) */
        this.usersTypingSet.add(
            this.findParticipantByIdentity(participant.identity)?.name,
        );
        this.updateTypingUsersIndicator();
    }

    userStoppedTyping(participant) {
        return this.updateUserStatus(
            {author: participant.identity},
            {status: STATUS.ACTIVE},
        );
        this.usersTypingSet.delete(
            this.findParticipantByIdentity(participant.identity)?.name,
        );
        this.updateTypingUsersIndicator();
    }

    updateUserStatus(twilioMessage, parsedBody) {
        if (!parsedBody?.status)
            return console.log("not possible updating status");

        const participant = this.findParticipantByIdentity(
            twilioMessage.author,
        );
        if (!participant) return;

        participant.status = parsedBody.status;
        // console.log("participant status .. ", participant?.user_name, participant.status);
        this.prepareParticipantsStatus();
    }

    prepareParticipantsStatus() {
        /*
            Present - Typing
            Present - Active
            Present - Inactive
            Absent (Online)
            Absent (Offline)
             */

        this.state.typingUsers.splice(0);
        this.activeUsers.splice(0);
        this.inActiveUsers.splice(0);
        this.absentUsers.splice(0);
        //this.offlineUsers.splice(0);

        this.getParticipants()?.forEach((participant) => {
            if (this.isParticipantAuthor(participant)) return; // console.log(" hmmm author ");
            const username = participant.name;
            // console.log("user and participant identity ",  participant.user_name,  participant.status)
            switch (participant.status) {
                case STATUS.TYPING: // 'Present Typing',
                    this.state.typingUsers.splice(0, 0, participant);
                    break;
                case STATUS.ACTIVE: // 'Present Active',
                    this.activeUsers.splice(0, 0, `${username} (PA)`);
                    break;
                case STATUS.INACTIVE: // 'Present Inactive',
                    this.inActiveUsers.splice(0, 0, `${username} (PI)`);
                    break;
                case STATUS.ABSENT: // 'Absent (Online)',
                case STATUS.OFFLINE: // 'Absent (Offline)'
                default:
                    this.absentUsers.splice(0, 0, `${username} (A)`);
                    break;
            }
        });

        if (this.isCurrentConversation()) this.updateUserStatusIndicators();
    }

    allParticipantsStatusExceptTyping() {
        const seperator = ", ";
        return [
            this.activeUsers.join(seperator),
            this.inActiveUsers.join(seperator),
            this.absentUsers.join(seperator),
        ]
            .filter((status) => status)
            .join(seperator);
    }

    // updateTypingUsersIndicator() {
    //     this.state.typingUser = [...this.usersTypingSet].join(', ');
    //     this.forceUiUpdate();
    // }

    whoIsTyping() {
        return this.state.typingUser;
    }

    getUserName(userIdentityAlias) {
        if (this.areMessagesBuffered()) return loggedInUserName();

        const pariticpiant = this.findParticipantByIdentity(userIdentityAlias);
        return pariticpiant?.name;
    }

    isUserParticipant(user_id) {
        return this.state.participants?.find(
            (paricipant) => paricipant.id == user_id,
        );
    }

    getParticipantByIdentity(paricipant_id) {
        return this.state.participants?.find(
            (paricipant) => paricipant.twilio_identity == paricipant_id,
        );
    }

    getParticipantByProfileId(profile_id) {
        return this.state.participants?.find(
            (paricipant) => paricipant.id == profile_id,
        );
    }

    getMessageAuthorName(message) {
        const pariticpiant = this.getParticipants()?.find(
            (user) => user.twilio_identity == message.author,
        );
        return pariticpiant?.name;
    }

    getMessageAuthorDetail(message) {
        const pariticpiant = this.getParticipants()?.find(
            (user) => user.twilio_identity == message.author,
        );
        return {name: pariticpiant?.name, avatar: pariticpiant?.avatar_url};
    }

    getMessageAuthor(message) {
        const participant = this.getParticipants()?.find(
            (user) => user.twilio_identity == message.author,
        );
        return participant || {};
    }

    isNotFromSameAuthorAndWithinSameMinitueAsPreviousMessage(
        messageIndex,
        message,
    ) {
        if (messageIndex == 0) return true;
        const previousMessage = this.getMessages()[messageIndex - 1];

        if (message.author != previousMessage.author) return true;

        return this.diff_minutes(
            message.dateCreated,
            previousMessage.dateCreated,
        );
    }

    isMessageNotOnSameDayAsPreviosMessage(messageIndex) {
        // console.log( "isMessageNotOnSameDayASPrevious" )
        const currentDate = this.state.messages[messageIndex]?.dateCreated;

        if (!currentDate) return;
        if (messageIndex == 0) {
            // console.log(`1 Diff @ ${messageIndex}`, DateUtilities.formatDate(currentDate))
            return DateUtilities.formatDate(currentDate);
        }

        const previousDate = this.state.messages[messageIndex - 1].dateCreated;
        const daysDiffIn2MessagesSendDate = DateUtilities.daysBetween(
            currentDate,
            previousDate,
        );
        // console.log(`2 Diff @ ${messageIndex}`, daysDiffIn2MessagesSendDate)
        /* if both messages are send on same date */
        if (daysDiffIn2MessagesSendDate == 0) return "";
        // console.log(` 3 Diff @ ${messageIndex}`, DateUtilities.formatDate(currentDate))
        return DateUtilities.formatDate(currentDate);
    }

    diff_minutes(dt2, dt1) {
        return DateUtilities.minutesBetween(dt1, dt2);
    }

    isToDisplayTimeStamp(message, messageIndex) {
        const previousMessage = this.getMessages()[messageIndex - 1];
        if (messageIndex == 0 || !previousMessage) return true;
        return (
            message.sent_time != previousMessage?.sent_time ||
            !message.has_same_author_as_previous_message ||
            message.is_deleted
        ); //|| message.attributes?.urgent_request;
    }

    formatMessageTimeStamp(message) {
        return intlDateTimeFormatter.value.format(message.dateCreated);
    }

    messageTime(message) {
        if (!message) return;
        return intlDateTimeFormatterOnlyTime.value.format(message.dateCreated);
    }

    isAuthorsMessage(message) {
        if (this.areMessagesBuffered()) return true;

        return this.getUserIdentity() == message?.author;
    }

    isParticipantAuthor(participant) {
        return this.getUserIdentity() == participant.twilio_identity;
    }

    getMessages() {
        if (this.cachedMessages.length) return this.cachedMessages;
        //if (this.isSendingBufferedMessagesInProgress) return [];
        if (
            this.isSendingBufferedMessagesInProgress ||
            this.areMessagesBuffered()
        )
            return this.state.bufferedMessages;
        return this.state.messages;
    }

    setComponentCallbackHandlers(
        scrollBarHandler,
        toggleUserOfInactivity,
        updateUserStatusIndicators,
    ) {
        this.scrollBarHandler = scrollBarHandler;
        this.toggleUserOfInactivity = toggleUserOfInactivity;
        this.updateUserStatusIndicators = updateUserStatusIndicators;
        this.moveScrollBar();
    }

    getTotalLoadedMessages() {
        return this.state.messages.length;
    }

    areMessagesBuffered() {
        return this.state.bufferedMessages.length;
    }

    areAllMessagesNotLoaded() {
        return this.state.total_messages != this.getTotalLoadedMessages();
    }

    sendToBuffer(messageDetail, type = MESSAGE.TEXT) {
        this.createConversation();

        const newMessage = this.prepareBufferedMessage(messageDetail, type);

        /* switch (type) {
                case 'text':
                    newMessage = { ...newMessage, ...messageDetail }
                    break;
                case 'media':
                    break;
                    case 'data':
                        newMessage  = { }
                        break;
                    } */

        /* if(type == 'media') {
                newMessage.body = { ...newMessage, URL.createObjectURL(message) };
                newMessage.media = message
            } */

        this.state.bufferedMessages.push(newMessage);
        this.moveScrollBar();
    }

    prepareBufferedMessage(messageDetail, type = MESSAGE.TEXT) {
        let newMessage = {
            author: this.getUserIdentity(),
            author_email: useUserStore().user_data.user?.email,
            is_author: true,
            dateCreated: new Date(),
            has_same_author_as_previous_message: false,
            is_previous_message_event: false,
            ...messageDetail,
            ...this.prepareMessageTypeFlagsWithType(type),
        };

        this.updateMessageAuthorAndTimestamp(
            this.state.bufferedMessages.length,
            newMessage,
        );
        this.checkPreviousNextEventMessage(
            this.state.bufferedMessages.length,
            newMessage,
        );

        return newMessage;
    }

    async sendBufferedMessages() {
        if (!this.areMessagesBuffered() || this.hasConversationNotCreated())
            return;

        this.isSendingBufferedMessagesInProgress = true;
        let message;

        for (const message of this.state.bufferedMessages) {
            let message_index, message_to_send;
            switch (message.type) {
                case MESSAGE.TEXT:
                    message_to_send = message.body;
                    //message_index = await this.sendToConversation(message.body)
                    //.then(message_index => this.getMessageFromNotProcessedByIndex(message_index))
                    //this.getMessageFromNotProcessedByIndex(message_index)
                    break;
                case MESSAGE.MEDIA:
                    await this.sendFile(message.media);
                    break;
                case MESSAGE.STATE:
                case MESSAGE.INVITE:
                case MESSAGE.UNINVITE:
                case MESSAGE.ASSIGN:
                case MESSAGE.UNASSIGN:
                case MESSAGE.NAVIGATION:
                case MESSAGE.ATTENTION:
                case MESSAGE.ATTENTION_CLOSED:
                case MESSAGE.REQUEST_STATUS:
                case MESSAGE.REQUEST_STATUS_CLOSED:
                case MESSAGE.SUPPORT:
                case MESSAGE.SUPPORT_CLOSED:
                    message_to_send = JSON.stringify(message.data);
                    //message_index = await this.sendToConversation(JSON.stringify(message.data))
                    //.then(message_index => this.getMessageFromNotProcessedByIndex(message_index))
                    //this.getMessageFromNotProcessedByIndex(message_index)
                    break;
                default:
                    console.log("Invalid message in sending buffered messages");
            }

            message_index = await this.sendToConversation(message_to_send);
            this.getMessageFromNotProcessedByIndex(message_index);
        }

        // this.state.bufferedMessages.forEach(async message => {
        // })

        // while (message = this.state.bufferedMessages[0]) {
        //     //console.log('message ..', message, Date.now());
        //     switch (message.type) {
        //         case 'text':
        //             await this.sendToConversation(message.body);
        //             break;
        //         case 'media':
        //             await this.sendFile(message.media);
        //             break;
        //         case 'state':
        //             await this.sendToConversation(JSON.stringify(message.data));
        //             break;
        //         default:
        //             console.log('Invalid message in sending buffered messages');
        //     }
        //     console.log("message sent..", message.body, Date.now());
        //     this.state.bufferedMessages.shift();
        // }

        this.state.bufferedMessages = [];
        this.isSendingBufferedMessagesInProgress = false;
        if (!(message = this.state.messages[0])) return;
        const messageTxt =
            message.type == MESSAGE.MEDIA ? "Shared a Image" : message.body;
        this.updateConversationLastMessageSentAtWith(messageTxt, true);
    }

    updateConversationLastMessageSentAtWith(
        message = "",
        are_buffered_messages_sent = false,
    ) {
        updateConversationLastMessageSentAt(this.getConversationId(), {
            are_buffered_messages_sent,
            message,
        });
    }

    async createConversation() {
        if (this.isRequestToCreateTwilioConversationAlreadyMade) return;

        try {
            this.setIsCreatingConversation(true);
            this.forceUiUpdate();
            this.isRequestToCreateTwilioConversationAlreadyMade = true;
            let newConversation;
            const payload = {
                type: this.type,
                id: this.type_id,
                participants_profile_ids: this.participants,
            };
            //console.log("payload", payload);
            const is_new_conversation = !this.id;

            const {data} = is_new_conversation
                ? await axios.post("/conversations", payload)
                : await axios.post(`/conversations/${this.id}/create`);

            const isGroupRelated = [TYPE.GROUP, TYPE.GROUP_ONE_OFF].includes(
                this.type,
            );

            if (!is_new_conversation && isGroupRelated) {
                const checked_invites = useTeam().temp_group_invitable_profile;
                useTeam().temp_group_invitable_profile = [];

                useConversation().inviteUsers(checked_invites, {});
            }
            newConversation = data;
            this.forceUiUpdate();

            if (newConversation) {
                this.restoreConversationData(newConversation);
            }

            /* update conv history if its new converation as
            not logged into chat hisotry as it was registered as fake before  */
            if (is_new_conversation)
                useConversation().openConversationById(this.id);
        } catch (e) {
            this.isRequestToCreateTwilioConversationAlreadyMade = false;
            this.forceUiUpdate();
            console.log("Error while creating Buffered Conversation", e);
        } finally {
            this.setIsCreatingConversation(false);
        }
    }

    restoreConversationData(conversation) {
        this.setHideLoadingIndicatorWith(true);
        this.setConversationVariables(conversation);
        this.initialize();
        this.isRequestToCreateTwilioConversationAlreadyMade = false;
    }

    async loadConversation() {
        try {
            this.forceUiUpdate();
            let newConversation;
            const payload = {type: this.type, id: this.type_id};
            const {data} = await axios.post("/conversations/get", payload);
            this.forceUiUpdate();

            if (!data) return console.log("conversaion not created it's empty");

            this.setHideLoadingIndicatorWith(true);
            this.setConversationVariables(data);
            this.initialize();
            const conversation_store = useConversation();
            conversation_store.setActiveConversationAtIndex(0);
        } catch (e) {
            this.forceUiUpdate();
            console.log("error while loading conversation", e);
        }
    }

    isItemConversationBeingCreated() {
        return this.isRequestToCreateTwilioConversationAlreadyMade;
    }

    resetWithConversation(conversationDetail) {
        this.setConversationVariables(conversationDetail);
        this.initialize();
    }

    updateLastMessageSentAt(last_message_send_at) {
        this.state.last_message_send_at = last_message_send_at;
    }

    /*  */
    isParticipantNew(newPartcipant) {
        const member = this.getParticipants()?.find(
            (participant) =>
                newPartcipant.identity == participant.twilio_identity,
        );
        return !member;
    }

    sendGroupInvitationMessages() {
        let authUser = this.authUser;
        let names = "";
        let ctr = 1;
        this.participants?.forEach((v) => {
            if (authUser.id != v.id) {
                if (this.participants.find((ov) => v.id === ov.id)) {
                    names += "&#x2022; " + v.name + " <br />";
                }
            }
            ctr++;
        });
        const message =
            authUser.first_name +
            " Created Group : <br /> " +
            authUser.first_name +
            " has added: <br />" +
            names;
        this.sendMessage(message);
    }

    beforeUpdateParticipantLastReadMessageIndex(
        index = this.getLatestMessageTwilioIndex(),
    ) {
        if (
            index >
            this.findParticipantByIdentity(this.auth_user_chat_identity)
                ?.last_read_message_index
        ) {
            this.updateAuthorsLastReadMessageIndexWith(
                this.getLatestMessageTwilioIndex(),
            );
        }
    }

    isDeletedMessage(message) {
        return message?.attributes?.status === "deleted";
    }

    updateMessageStateAsFailedByIndex(message_index) {
        //console.log(this.state.messages[message_index])
        this.failedMessage(this.state.messages[message_index]);
    }

    async failedMessage(message) {
        await message.updateAttributes(JSON.stringify({status: "failed"}));
    }

    async removeMessage(message) {
        const has_image = checkImageExists(message.body);
        if (has_image) {
            const image_urls = grabImageUrls(message.body);
            image_urls.forEach((image_url) => {
                const index = getIndexFromImageSrc(image_url);
                this.removeMedia(index);
            });
        }
        await message.updateAttributes(JSON.stringify({status: "deleted"}));
        await message.updateBody("Message deleted");
    }

    async sendAssignedMessage(new_assigned_id, currently_assigned_id) {
        let ret_val = [];
        const item_category = this.getItemCategory();
        /*if (!new_assigned_id)
            return
*/
        if (
            !item_category?.automatic_end_assignment &&
            new_assigned_id != currently_assigned_id
        ) {
            if (currently_assigned_id && new_assigned_id) {
                await Promise.all([
                    this.sendInvitationUpdateMessages(
                        [currently_assigned_id],
                        "assign",
                        "Unassigned",
                    ),
                    this.sendInvitationUpdateMessages(
                        [new_assigned_id],
                        "assign",
                        "Assigned",
                    ),
                ]).then((values) => {
                    ret_val.push(values[0]);
                    ret_val.push(values[1]);
                });
            } else {
                // End assignment is handled at backend.
                //if (currently_assigned_id && !new_assigned_id)
                //    ret_val.push(await this.sendInvitationUpdateMessages([currently_assigned_id], 'assign', 'Assignment Ended'))

                if (new_assigned_id)
                    ret_val.push(
                        await this.sendInvitationUpdateMessages(
                            [new_assigned_id],
                            "assign",
                            "Assigned",
                        ),
                    );
            }
        }

        this.setAssignee(new_assigned_id);

        return ret_val;
    }

    async sendNavigationMessage() {
        this.sendJSONData(
            {
                type: MESSAGE.NAVIGATION,
                title: document.title,
                link: window.location.origin + window.location.pathname,
            },
            MESSAGE.NAVIGATION,
        );
    }

    async sendTimerEventMessage(event_type, message, action) {
        this.sendJSONData(
            {
                type: MESSAGE.TIMER,
                sub_type: event_type,
                message: message,
                action: action,
            },
            MESSAGE.TIMER,
        );
    }

    async sendInvitationForProfiles(new_profile_ids) {
        return await this.sendInvitationUpdateMessages(
            new_profile_ids,
            "invite",
            "Added",
        );
    }

    async sendRemovedInvitationForProfiles(deleted_profile_ids) {
        return await this.sendInvitationUpdateMessages(
            deleted_profile_ids,
            "invite",
            "Removed",
        );
    }

    async sendGroupCreationInvitaitonForProfiles(new_profile_ids) {
        useTeam().temp_group_invitable_profile = new_profile_ids;
        await this.sendMessage("Group Created");
        return await this.sendInvitationUpdateMessages(
            new_profile_ids,
            "invite",
            "Added",
        );
    }

    async sendInvitationUpdateMessages(profile_ids, type, message) {
        if (!profile_ids?.length) return;

        let data = {
            type: type,
            message: message,
            user_profiles: [],
        };
        const unique_profile_ids = [...new Set(profile_ids)];
        unique_profile_ids.forEach((user) => {
            let user_details = this.getParticipants().find(
                (v) => v.id === user,
            );

            if (user_details)
                data?.user_profiles?.push({
                    id: user_details.id,
                    name: user_details.full_name,
                    avatar_url: user_details.avatar_url,
                });
            else {
                user_details = useUserStore().profiles.find(
                    (v) => v.id === user,
                );
                if (user_details && user !== window.profile.id)
                    data?.user_profiles?.push({
                        id: user_details.id,
                        name: user_details.full_name,
                        avatar_url: user_details.avatar_url,
                    });
            }
        });

        if (!data.user_profiles?.length)
            return console.log("send invitation message " + profile_ids);

        return this.sendJSONData(data, type);
    }

    async sendUrgentMessages(profiles, type, message) {
        if (!profiles?.length) return;

        let data = {
            type: type,
            message: message,
            user_profiles: profiles.map((profile) => {
                return {
                    id: profile['id'],
                    first_name: profile['first_name'],
                    last_name: profile['last_name'],
                    avatar_url: profile['avatar_url'],
                    full_name: profile['full_name'],
                    display_name: profile['display_name'],
                    twilio_identity: profile['twilio_identity'],
                    user_profile_id: profile['user_profile_id'],
                    organization_id: profile['organization_id']
                }
            })
        };


        if (!data.user_profiles?.length)
            return console.log("send urgent message " + profile_ids);

        return this.sendJSONData(data, type);
    }

    updateParticipantRemovedStatus(participant_id) {
        const participant = this.getParticipantByIdentity(participant_id);
        if (!participant) return;

        participant.is_removed = true;
    }

    areBufferedMessagesEmpty() {
        return this.state.bufferedMessages.length <= 0;
    }

    setInvitationInProgress(val) {
        return (this.state.is_invitation_in_progress = val);
    }

    getLastMessageIndexFromNewlyLoadedMessages() {
        return this.state.lastMessageIndexFromNewlyLoadedMessages;
    }

    setIsCreatingConversation(val) {
        this.state.isCreatingConversation = val;
    }

    addProfileData(parsedBody) {
        parsedBody?.user_profiles?.forEach((profile) => {
            let participant = this.getParticipantByProfileId(profile?.id);

            if (!participant) {
                participant = useUserStore().profiles.find(
                    (v) => v.id === profile?.id,
                );
            }

            if (participant) {
                profile.name = participant?.full_name;
            }

            if (profile) profile.avatar_url = participant?.avatar_url;
        });
    }

    setReservedMessage(val) {
        this.state.reserved_messages = val;
    }

    pushReservedMessage(val) {
        this.state.reserved_messages.push(val);
    }

    removeKaamfuRequest(id) {
        if (id) {
            axios.delete(`/kaamfu-requests/${id}`);
        }
    }

    updateAttributes(attr) {
        if (attr.length == 0) {
            this.setupUrgentRequestsKaamfu([]);
        }

        return axios
            .post(`/kaamfu-requests`, {
                attributes: {
                    urgent_request: attr,
                    last_user_to_update: useUserStore().getTwilioIdentity,
                    date_updated: Date.now(),
                },
                type: "attention",
                model_id: this.id,
            })
            .then((response) => {
                if (!response.data.data.reply) {
                    this.setupUrgentRequestsKaamfu(
                        response?.data?.data?.models,
                    );
                }
            });
    }

    async getAttributes() {
        return await this.twilioConversation.value.getAttributes();
    }

    restoreMessages(messages) {
        messages.forEach((message) => {
            message.dateCreated = new Date(message.dateCreated);
            message.dateUpdated = new Date(message.dateUpdated);
            this.addNewMessage(message, this.parseTwilioMessageBody(message));
        });
        this.moveScrollBar();
    }

    async restoreConversation() {
        if (this.isRequestToCreateTwilioConversationAlreadyMade) {
            return;
        }
        try {
            this.isRequestToCreateTwilioConversationAlreadyMade = true;
            this.removeInitialMessagesFetched();
            this.setIsRequireClearMessages();
            const {data} = await axios.post(
                `/conversations/${this.state.id}/restore`,
            );
            this.setInvitationInProgress(false);
            this.forceUiUpdate();
            this.setConversationVariables(data.data);
            this.initialize();
            //this.setHideLoadingIndicatorWith(false);
            this.isRequestToCreateTwilioConversationAlreadyMade = false;
        } catch (error) {
            this.isRequestToCreateTwilioConversationAlreadyMade = false;
            if (error?.response?.status === HttpStatusCodes.FORBIDDEN) {
                Utilities().showErrorSnackbar(error?.response?.data?.message);
            }
            console.log("restoreConversation failed", error);
            this.setAsArchivedConversation();
        }
    }

    async removeMedia(index) {
        try {
            await axios.delete(`/medias/${index}`);
        } catch (error) {
            console.log("Error occured while delete");
        }
    }

    removeAllParticipants() {
        // this.state.participants = [];
    }

    firstUnReadMessage() {
        return this.getMessages()[this.unreadMessagesCount - 1];
    }
}

export default Conversation;
