<template>
    <BaseModal>
        <template #header>
            <div class="prospus-modal__header--actions">
                <img
                    src="/public/images/kaamfu-wide-k.png"
                    class="prospus-logo"
                    alt="kaamfu-logo-modal"
                />
                <span class="prospus-close" @click="$emit('close')"
                    >&times;</span
                >
            </div>
            <h3 class="prospus-title">Forgot Password</h3>
        </template>
        <div>
            <form
                v-if="!isPasswordResetLinkSent"
                class="prospus-form"
                @submit.prevent
                @keyup.enter.prevent="onSubmit"
            >
                <div class="prospus-email-wrap">
                    <label for="email"
                        >Please enter the email address associated with this
                        account</label
                    >
                    <div
                        class="input-wrapper prospus-has-validation-check"
                        :class="loginErrorMessages.email ? 'has-error' : ''"
                    >
                        <div class="input-wrapper__inner">
                            <button
                                type="button"
                                :class="[
                                    email && loginErrorMessages.email
                                        ? 'hint-mode'
                                        : '',
                                ]"
                            >
                                <span class="material-symbols-rounded">{{
                                    emailValidateIcon
                                }}</span>
                            </button>
                            <input
                                ref="resetPwdEmail"
                                v-model="email"
                                type="email"
                                name="email"
                                title="email"
                                autofocus
                                autocomplete="false"
                                @focusout="loginErrorMessages.email = false"
                                @keypress="loginErrorMessages.email = ''"
                            />
                        </div>
                    </div>
                </div>
                <div class="prospus-submit prospus-flex-center-y">
                    <a
                        v-if="!isRequestInProgress"
                        class="prospus-btn prospus-b-none prospus-mr-10"
                        @click.stop="$emit('close')"
                    >
                        Cancel
                    </a>
                    <input
                        class="prospus-btn btn-loader prospus-b-2 text--red"
                        :class="{
                            'btn-white prospus-btn--disabled':
                                isRequestInProgress || !isEmailValid,
                        }"
                        type="button"
                        value="Submit"
                        :disabled="isRequestInProgress"
                        @click="sendPassordResetLink"
                    />
                </div>
                <div v-if="responseErrorText">
                    {{ responseErrorText }}
                </div>
            </form>
            <div
                v-else
                class="prospus-form prospus-flex prospus-flex-column prospus-flex-center"
            >
                <div>
                    If there is an account associated with {{ email }}, please
                    check your inbox and follow the instructions to reset your
                    password.
                </div>
                <div class="prospus-mt-20">
                    <a
                        v-if="!isRequestInProgress"
                        class="prospus-btn"
                        @click.stop="$emit('close')"
                    >
                        Close
                    </a>
                </div>
            </div>
        </div>
    </BaseModal>
</template>
<script>
import axios from "axios";
import BaseModal from "../Common/BaseModal.vue";
export default {
    components: {
        BaseModal,
    },
    data() {
        return {
            email: "",
            isRequestInProgress: false,
            isPasswordResetLinkSent: false,
            responseErrorText: false,
            loginErrorMessages: {
                email: false,
                password: false,
            },
        };
    },
    computed: {
        emailValidateIcon() {
            if (this.email === "") return "mail";
            else if (this.verifyEmail()) return "task_alt";
            else return "highlight_off";
        },
        isEmailValid() {
            return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/.test(
                this.email,
            );
        },
    },
    mounted() {
        this.setFocusToEmailField();
    },
    methods: {
        setFocusToEmailField() {
            const timeOutHandler = setTimeout(
                () => (
                    this.$refs.resetPwdEmail.focus(),
                    clearTimeout(timeOutHandler)
                ),
                400,
            );
        },
        async sendPassordResetLink() {
            if (this.isRequestInProgress) return;
            this.isRequestInProgress = true;
            this.responseErrorText = "";
            try {
                await axios.post("/forgot-password", { email: this.email });
                this.isPasswordResetLinkSent = true;
                this.responseErrorText = "";
                this.isRequestInProgress = true;
            } catch (error) {
                this.update422ErrorResponseMessage(error.response);
            }
            this.isRequestInProgress = false;
        },
        update422ErrorResponseMessage(response) {
            if (response?.status == 422)
                this.responseErrorText = response?.data?.message;
        },
        verifyEmail() {
            if (this.isEmailValid) return true;

            this.loginErrorMessages.email = true;
            return false;
        },
        onSubmit() {
            this.sendPassordResetLink();
        },
    },
};
</script>
